import { HashRouter, Link, Redirect, Route, useHistory, useLocation, Switch } from 'react-router-dom';
import {
  IonApp,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToast,
  IonToolbar,
  setupIonicReact,
  useIonRouter,
  useIonToast
} from '@ionic/react';
import { IonReactHashRouter, IonReactRouter } from '@ionic/react-router';
import Signup from './pages/Signup';
import Login from './pages/Login';
import Home from './pages/Home';
import ForgotPass from "./pages/ForgotPassword";
import UserProfile from "./pages/UserProfile";
import ChangePassword from './pages/ChangePassword';
import Adminpage from './pages/Adminpage';
import Deity from './pages/admin/Deitymanage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import { useContext, useEffect, useState } from "react"
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import { App as CapApp } from '@capacitor/app';





/* Theme variables */
import './theme/variables.css';
import './pages/Userpage.css'
import './pages/admin/adminstylesheet.css'
import VerifyLink from './pages/Verifylink';
import Temples from "./pages/Temples"
import Poojaofferings from "./pages/Poojaofferings";
import Poojamaterials from "./pages/Poojamaterials";
import TempAdmin from './pages/admin/TempleManagement';
import Myorder from "./pages/Myorder";
import Order from './pages/admin/ordermanagement';
import OrderAdm from './pages/admin/Order';
import Mycart from './pages/Mycart';
import Materialmanage from './pages/admin/Materialmanage';
import Mymaterial from './pages/Mymaterial';
import MaterialsVen from './pages/admin/VendorMaterials';
import Grammercemanage from './pages/admin/Grammercemanage';
import Grammerce from './pages/Grammerce';
import Mygrammerce from './pages/Mygrammerce';
import Materialordermanage from './pages/admin/vendorordermaterial';
import Admmaterialorder from './pages/admin/admmaterialorder';
import Admgrammereceorder from './pages/admin/admgrammerceorder';
import Donations from './pages/Donations';
import Donationmanage from './pages/admin/Donationmanage';
import Mydonation from './pages/MyDonation';
import ProjMan from './pages/admin/projectManagement';
import Projectmanage from './pages/admin/Projectmanage';
import Project from './pages/project';

import Myprojectdonation from './pages/MyProjectDonation';
import Projectdonation from './pages/admin/projectdonation';
import Projectdonationadmin from './pages/admin/Projectdonationadmin';
import ProjectDonationUser from './pages/ProjectDonationUser';
import About from './pages/About';
import Ser from './pages/Serv';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import News from './pages/News';
import TourPackages from './pages/TourPackages';
import Peetam from './pages/Peetam';
import Terms from './pages/Terms';
import Enquiry from './pages/Enquiry';
import Notifications from './pages/Notifications';

import Downloads from './pages/Downloads';
import RefundPolicy from './pages/RefundPolicy';
import JoinUs from './pages/JoinUs';
import Admtemple from './pages/admin/Admtemple';
import { SplashScreen } from '@capacitor/splash-screen';
import { App as CapacitorApp } from '@capacitor/app';
import UserHead from './pages/UserHead';
import Foot from './pages/Foot';
import HeadFoot from './pages/Head';
import { Capacitor } from '@capacitor/core';
import { arrowDown, arrowForward } from 'ionicons/icons';
import { Router } from 'workbox-routing';
import SessionTimeout from './Services/SessionTimeOut';
import AppUrlListener from './components/AppUrlListener';
import { Deeplinks } from '@ionic-native/deeplinks';
import { Plugins } from '@capacitor/core';
import AdminHomePage from './pages/admin/adminHomePage';
import Addtemp from './pages/admin/addtemple';
import TempleAdmDet from './pages/admin/TempDetails';
import { AppContext } from './components/AppContext';
import { propTypes } from 'react-bootstrap/esm/Image';
import Report from './pages/admin/report';
import TermsConditions from './pages/Terms&conditions';
import { fetchUserDetails } from './components/userInformation';
import HomePageHeader from './pages/admin/carouselManagement';
import Volunteer from './pages/Volunteer';
import Transportation from './pages/transportation';
import TempleAddEdit from './pages/admin/templeAddEdit';
import AdmtempleList from './pages/admin/adminTempleList';
import OfferManagement from './pages/admin/offermanagement';
import FundReport from './pages/admin/fundreport';
import PrasadhamDelivery from './pages/PrasadhamDelivery';
import RefundReport from './pages/admin/refunds';
import DistrictComponenet from './pages/admin/DistrictComponent';
import Localmarket from './pages/localmarket';
import MarketManagement from './pages/admin/marketManagement';
import FundDisbursed from './pages/admin/funddisbursed';
import VDC from './pages/admin/vdccharges';
import ImportFile from './pages/admin/Importfile';
import VerifyEmail from './pages/verifyemail';
import ReviewList from './pages/admin/reviewList';
import FundForVolunteer from './pages/admin/fundforvolunteer';
import UserBookingOrders from './pages/userBookingOrders';


setupIonicReact();
var assign: any

const App: React.FC = () => {
  const [counter, setCounter] = useState(60);
  const [userdata] = useState(sessionStorage.getItem("UserData"));
  //var userdata = sessionStorage.getItem("UserData")
  const [uFlag] = useState(sessionStorage.getItem("uFlag"));
  //var uFlag = sessionStorage.getItem("uFlag")
  var token = sessionStorage.getItem("token")

  const pathname = window.location.hash.substr(1);
  const shouldRenderHeader = pathname != '/home' && pathname != "";

  const router: any = useIonRouter();
  var history: any = useHistory();
  var usertoken = sessionStorage.getItem("token");
  const [isPoojaVisible, setIsPoojaVisible] = useState(false);
  const [isGraVisible, setIsGraisible] = useState(false);
  const [isProjVisible, setIsProjisible] = useState(false);
  var isOpen = false;
  const { Deeplinks } = Plugins;
  const { App } = Plugins;

  const [present] = useIonToast();




  useEffect(() => {
    console.log('Current pathname:', pathname);
    console.log('shouldRenderHeader:', shouldRenderHeader);
    console.log(usertoken)
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (!canGoBack) {
        if (isOpen) {
          // setIsOpen(false)
          console.log("isopn" + isOpen)

          CapacitorApp.exitApp();
        } else {
          console.log("isopn1" + isOpen)
          isOpen = true;
          presentToast();
        }


      } else {
        isOpen = false;
        window.history.back();
      }
    });



    // // Function to handle deep link URLs
    // const handleDeepLinks = async () => {
    //   // Check if the app was opened via a deep link URL
    //   var link = await CapApp.getLaunchUrl();
    //   if (link && link.url) {
    //     handleOpenUrl(link.url);
    //   }

    //   // Add a listener for appUrlOpen event
    //   CapApp.addListener('appUrlOpen', (data) => {
    //     if (data && data.url) {
    //       handleOpenUrl(data.url);
    //     }
    //   });
    // };

    // handleDeepLinks();
    //  // Configure deep links
    //  Deeplinks.route({
    //   '/verifyMobile/:token': {},
    // }).subscribe((match: any) => {
    //   // Handle the matched route
    //   const { id } = match.$args;
    //   console.log('Universal link matched:', id);
    //   // Perform actions or navigate to a specific page based on the matched route
    // });

    // // Start listening for deep links
    // Deeplinks.routeWithNavController(window.location.href, {
    //   // Add any additional configuration options here
    // });

    //SplashScreen.hide();
    // Create a new instance of the Deeplinks plugin
    // const handleDeepLink = async () => {
    //   const ret = await App.getLaunchUrl();
    //   if (ret && ret.url) {
    //     console.log('Deep link URL:', ret.url);
    //   }
    // };
    // handleDeepLink();
    // const appUrlOpenHandler = App.addListener('appUrlOpen', handleDeepLink);
    // return () => {
    //   appUrlOpenHandler && appUrlOpenHandler.remove();
    // };




  }, []);



  const presentToast = () => {
    present({
      message: 'press again to exit App..',
      duration: 1500,

    });
  };
  const handleOpenUrl = (url: string) => {
    // Handle the deep link URL as needed
    console.log('Opened URL:', url);
  };

  return (

    <IonApp>

      {/* 
        <IonMenuToggle auto-hide="false">
          <IonItem button>
            <IonIcon slot="start" name='home'></IonIcon>
            <IonLabel>
              Home
            </IonLabel>
          </IonItem>
        </IonMenuToggle> */}
      {/* {shouldRenderHeader && ( */}
      {/* )} */}
      <HashRouter>
        {/* {userdata != null && userdata != '' && userdata != undefined ? <IonMenu content-id="main-content" id="menu_id">
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>Atmagram</IonTitle>
              </IonToolbar>
            </IonHeader>

            <IonContent>
              <IonList>
                <div>
                  {userdata === "ROLE_USER" ? (
                    <IonMenuToggle auto-hide="false">
                      <IonList>


                        <Link to="/home" className='link_cls'>
                          <IonItem>
                            <span>
                              Home
                            </span>
                          </IonItem>
                        </Link>


                        {uFlag === 'true' &&


                          <Link to="/temples" className='link_cls'>
                            <IonItem>
                              <span>
                                Temples
                              </span>
                            </IonItem>
                          </Link>
                        }
                        {uFlag === 'true' &&

                          <Link to="/offerings" className='link_cls'>
                            <IonItem>
                              <span>
                                Pooja Offerings
                              </span></IonItem>
                          </Link>

                        }
                        {uFlag === 'true' &&

                          <Link to="/materials" className='link_cls'>
                            <IonItem>
                              <span>
                                Pooja Materials
                              </span>
                            </IonItem>
                          </Link>

                        }
                        {uFlag === 'true' &&

                          <Link to="/grammerce" className='link_cls'>
                            <IonItem>
                              <span>
                                Grammerce
                              </span>
                            </IonItem>
                          </Link>

                        }
                        {uFlag === 'true' &&

                          <Link to="/donations" className='link_cls'>
                            <IonItem>
                              <span>
                                Donations
                              </span>
                            </IonItem>
                          </Link>

                        }
                        {uFlag === 'true' &&

                          <Link to="/project" className='link_cls'>
                            <IonItem>
                              <span>
                                Projects
                              </span>
                            </IonItem>
                          </Link>

                        }
                      </IonList>
                    </IonMenuToggle>

                  ) : (
                    <div>
                      {userdata === "ROLE_ADMIN" && uFlag === 'true' ? (




                        <div>
                          <IonMenuToggle auto-hide="false">
                            <IonList>

                              <Link to='admin_page' className='link_cls'>
                                <IonItem>
                                  <span>
                                    User Management
                                  </span>
                                </IonItem>
                              </Link>


                              <Link to="/deity" className='link_cls'>
                                <IonItem>
                                  <span>
                                    Deity Management
                                  </span>
                                </IonItem>
                              </Link>


                              <Link to="/orderAdmin" className='link_cls'>
                                <IonItem>
                                  <span>
                                    Offering Order Management
                                  </span>
                                </IonItem>
                              </Link>


                              <Link to="/admtemple" className='link_cls'>
                                <IonItem>
                                  <span>
                                    Temple Management
                                  </span>
                                </IonItem>
                              </Link>


                              <Link to="/donationmanage" className='link_cls'>
                                <IonItem>
                                  <span>
                                    Donation Management
                                  </span>
                                </IonItem>
                              </Link>

                            </IonList>
                          </IonMenuToggle>
                          <IonItem
                            button
                            onClick={() => {
                              setIsPoojaVisible(true);
                              if (isPoojaVisible === true) {
                                setIsPoojaVisible(false);
                              }
                            }}
                          >

                            <IonLabel>Pooja Material</IonLabel>
                            <IonIcon
                              slot="end"
                              icon={isPoojaVisible ? arrowDown : arrowForward}
                            ></IonIcon>
                          </IonItem>
                          <IonMenuToggle>
                            <IonList>


                              <Link to="/materialadm" className='link_cls' hidden={!isPoojaVisible}>
                                <IonItem

                                  button

                                >
                                  <span >
                                    Material Management
                                  </span></IonItem>
                              </Link>



                              <Link to="/admmaterialorders" className='link_cls' hidden={!isPoojaVisible}>
                                <IonItem

                                  button

                                >
                                  <span>
                                    Material Order Management
                                  </span> </IonItem>
                              </Link>

                            </IonList>
                          </IonMenuToggle>

                          <IonItem
                            button
                            onClick={() => {
                              setIsGraisible(true);
                              if (isGraVisible === true) {
                                setIsGraisible(false);
                              }
                            }}
                          >

                            <IonLabel>Grammerce</IonLabel>
                            <IonIcon
                              slot="end"
                              icon={isGraVisible ? arrowDown : arrowForward}
                            ></IonIcon>
                          </IonItem>
                          <IonMenuToggle>
                            <IonList>


                              <Link to="/grammerceadm" className='link_cls' hidden={!isGraVisible}>
                                <IonItem

                                  button

                                >
                                  <span >
                                    Grammerce Management
                                  </span> </IonItem>
                              </Link>


                              <Link to="/grammerceorderadm" className='link_cls' hidden={!isGraVisible}>
                                <IonItem

                                  button

                                >

                                  <span>
                                    Grammerce Order Management
                                  </span> </IonItem>
                              </Link>

                            </IonList>
                          </IonMenuToggle>


                          <IonItem
                            button
                            onClick={() => {
                              setIsProjisible(true);
                              if (isProjVisible === true) {
                                setIsProjisible(false);
                              }
                            }}
                          >

                            <IonLabel>Project</IonLabel>
                            <IonIcon
                              slot="end"
                              icon={isProjVisible ? arrowDown : arrowForward}
                            ></IonIcon>
                          </IonItem>
                          <IonMenuToggle>
                            <IonList>


                              <Link to="/projectmanage" className='link_cls' hidden={!isProjVisible}>
                                <IonItem

                                  button

                                >
                                  <span >
                                    Project Management
                                  </span> </IonItem>
                              </Link>



                              <Link to="/donationadm" className='link_cls' hidden={!isProjVisible}>
                                <IonItem

                                  button

                                >
                                  <span>
                                    Project Donation
                                  </span></IonItem>
                              </Link>

                            </IonList>
                          </IonMenuToggle>



                          <div className="row_headers">

                            {/* <div className="gap_between">
                    <IonText className={local.pathname === "/materialadm" ? "active-tab" : local.pathname === "/admmaterialorders" ? "active-tab" : "inactive-tab"} onClick={openPopover}>Pooja Material</IonText>
                    <IonPopover ref={popover} isOpen={popoverOpen} onDidDismiss={() => setPopoverOpen(false)}>
                      <IonContent class="ion-padding">
                        <div className="gap_between" onClickCapture={() => setPopoverOpen(false)}>
                          <Link to="/materialadm" style={{ textDecoration: "none" }}>
                            <span className={local.pathname == "/materialadm" ? "active-tab" : "inactive-tab"}>
                              Material Management
                            </span>
                          </Link>
                        </div>
                        <div className="gap_between" onClickCapture={() => setPopoverOpen(false)}>
                          <Link to="/admmaterialorders" style={{ textDecoration: "none" }}>
                            <span className={local.pathname == "/admmaterialorders" ? "active-tab" : "inactive-tab"}>
                              Material Order Management
                            </span>
                          </Link>
                        </div>
                      </IonContent>
                    </IonPopover>
                  </div>
                  <div className="gap_between">
                    <IonText className={local.pathname === "/grammerceadm" ? "active-tab" : local.pathname === "/grammerceorderadm" ? "active-tab" : "inactive-tab"} onClick={openPopover1}>Grammerce</IonText>
                    <IonPopover ref={popover1} isOpen={popoverOpen1} onDidDismiss={() => setPopoverOpen1(false)}>
                      <IonContent className="ion-padding">
                        <div className="gap_between" style={{ width: "max-content" }} onClickCapture={() => setPopoverOpen1(false)}>
                          <Link to="/grammerceadm" style={{ textDecoration: "none" }}>
                            <span className={local.pathname == "/grammerceadm" ? "active-tab" : "inactive-tab"}>
                              Grammerce Management
                            </span>
                          </Link>
                        </div>
                        <div className="gap_between" style={{ width: "max-content" }} onClickCapture={() => setPopoverOpen1(false)}>
                          <Link to="/grammerceorderadm" style={{ textDecoration: "none" }}>
                            <span className={local.pathname === "/grammerceorderadm" ? "active-tab" : "inactive-tab"}>
                              Grammerce Order Management
                            </span>
                          </Link>
                        </div>
                      </IonContent>
                    </IonPopover>
                  </div> */}

        {/* <div className="gap_between">
                    <IonText className={local.pathname === "/projectmanage" ? "active-tab" : local.pathname === "/donationadm" ? "active-tab" : "inactive-tab"} onClick={openPopover2}>Project</IonText>
                    <IonPopover ref={popover2} isOpen={popoverOpen2} onDidDismiss={() => setPopoverOpen2(false)}>
                      <IonContent class="ion-padding">
                        <div className="gap_between" onClickCapture={() => setPopoverOpen2(false)}>
                          <Link to="/projectmanage" style={{ textDecoration: "none" }}>
                            <span className={local.pathname === "/projectmanage" ? "active-tab" : "inactive-tab"}>
                              Project Management
                            </span>
                          </Link>
                        </div>
                        <div className="gap_between" onClickCapture={() => setPopoverOpen2(false)}>
                          <Link to="/donationadm" style={{ textDecoration: "none" }}>
                            <span className={local.pathname === "/donationadm" ? "active-tab" : "inactive-tab"}>
                              Project Donation
                            </span>
                          </Link>
                        </div>
                      </IonContent>
                    </IonPopover>
                  </div> 
                          </div>
                        </div>

                      ) : userdata === "ROLE_TEMPADMIN" && uFlag === 'true' ? (
                        <IonMenuToggle auto-hide="false">
                          <IonList>

                            <Link to='tempadmin' className='link_cls'>
                              <IonItem>
                                <span>
                                  Temple Management
                                </span> </IonItem>
                            </Link>



                            <Link to="/order" className='link_cls'>
                              <IonItem>
                                <span>
                                  Order Management
                                </span></IonItem>
                            </Link>



                          </IonList>
                        </IonMenuToggle>



                      ) : userdata === "ROLE_VENDOR" && uFlag === 'true' ? (
                        <IonMenuToggle auto-hide="false">
                          <IonList>


                            <Link to='/vendormaterials' className='link_cls'>
                              <IonItem>
                                <span>
                                  Material Management
                                </span> </IonItem>
                            </Link>



                            <Link to="/vendormateriaorders" className='link_cls'>
                              <IonItem>
                                <span>
                                  Material Order Management
                                </span> </IonItem>
                            </Link>


                          </IonList>
                        </IonMenuToggle>


                      ) : userdata === "ROLE_PROJECTADMIN" && uFlag === 'true' ? (
                        <IonMenuToggle auto-hide="false">
                          <IonList>



                            <Link to="/projectadmin" className='link_cls'>
                              <IonItem>
                                <span>
                                  Project Management
                                </span> </IonItem>
                            </Link>




                            <Link to="/projectdonate" className='link_cls'>
                              <IonItem>
                                <span>
                                  Project Donation
                                </span> </IonItem>
                            </Link>




                          </IonList>
                        </IonMenuToggle>


                      ) : <div>{Capacitor.getPlatform() === "web" ? <IonMenuToggle auto-hide="false">

                        <IonList lines="none">

                          <Link to="/home" className='link_cls'>
                            <IonItem>
                              <span>
                                Home
                              </span></IonItem>
                          </Link>




                          <Link to="/temples" className='link_cls'>
                            <IonItem>
                              <span>
                                Temples
                              </span></IonItem>
                          </Link>

                          <Link to="/offerings" className='link_cls'>
                            <IonItem>
                              <span >
                                Pooja Offerings
                              </span></IonItem>
                          </Link>

                          <Link to="/materials" className='link_cls'>
                            <IonItem>
                              <span>
                                Pooja Materials
                              </span></IonItem>
                          </Link>

                          <Link to="/grammerce" className='link_cls'>
                            <IonItem>
                              <span >
                                Grammerce
                              </span></IonItem>
                          </Link>

                          <Link to="/donations" className='link_cls'>
                            <IonItem>
                              <span>
                                Donations
                              </span></IonItem>
                          </Link>



                          <Link to="/project" className='link_cls'>
                            <IonItem>
                              <span>
                                Projects
                              </span></IonItem>
                          </Link>

                        </IonList>

                      </IonMenuToggle> : <></>}</div>}
                    </div>
                  )}


                </div>


              </IonList>
            </IonContent>
          </IonMenu> : <></>} */}

        {/* {shouldRenderHeader && (
            <IonHeader id="main-content">
              <HeadFoot User="login" Role="Role" />
            </IonHeader>
          )} */}

        {/* {local.pathname === "/home" && */}

        {/* } */}
        {/* <IonContent fullscreen style={{paddingTop:"10%"}}>

          </IonContent> */}
        <AppUrlListener></AppUrlListener>
        <SessionTimeout />

        <Route render={(props) => <AdminHomePage {...props} key={Date.now()} />} path="/admin_home">
        </Route>
        {/* <Route render={(props) => <Signup {...props} key={Date.now()} />} exact path="/signup">
          </Route> */}
        <Route render={(props) =>
          userdata === null ? <Signup {...props} key={Date.now()} />
            : (userdata === "ROLE_ADMIN" ? <Adminpage {...props} key={Date.now()} />
              : userdata === "ROLE_TEMPADMIN" ? <TempAdmin {...props} key={Date.now()} />
                : userdata === "ROLE_VENDOR" ? <MaterialsVen {...props} key={Date.now()} />
                  : userdata === "ROLE_PROJECTADMIN" ? <Projectmanage {...props} key={Date.now()} />
                    : <Home {...props} key={Date.now()} />
            )
        }
          exact path="/signup">
        </Route>
        <Route render={(props) =>
          // userdata === null ? 
          <Login {...props} key={Date.now()} />
          // : (userdata === "ROLE_ADMIN" ? <Adminpage {...props} key={Date.now()} />
          //   : userdata === "ROLE_TEMPADMIN" ? <TempAdmin {...props} key={Date.now()} />
          //     : userdata === "ROLE_VENDOR" ? <MaterialsVen {...props} key={Date.now()} />
          //       : userdata === "ROLE_PROJECTADMIN" ? <Projectmanage {...props} key={Date.now()} />
          //         : <Home {...props} key={Date.now()} />
          // )
        }
          path="/login">
        </Route>
        {/* <Route render={(props) => <Login {...props} key={Date.now()} />} exact path="/login">
          </Route> */}
        <Route render={(props) =>
          // userdata === null ? <Home {...props} key={Date.now()} />
          //   : (userdata === "ROLE_ADMIN" ? <Adminpage {...props} key={Date.now()} />
          //     : userdata === "ROLE_TEMPADMIN" ? <TempAdmin {...props} key={Date.now()} />
          //       : userdata === "ROLE_VENDOR" ? <MaterialsVen {...props} key={Date.now()} />
          //         : userdata === "ROLE_PROJECTADMIN" ? <Projectmanage {...props} key={Date.now()} />
          //           : 
          <Home {...props} key={Date.now()} />
          // )
        }
          exact path="/home">
        </Route>

        {/* <Route render={(props) => <Home {...props} key={Date.now()} />} path="/home">
          </Route> */}
        {/* <Route render={(props) =>
            usertoken === null ? <Login /> : <Home {...props} key={Date.now()} />
          }
            exact path="/">
          </Route> */}
        <Route render={(props) =>
          <Home {...props} key={Date.now()} />
        }
          exact path="/">
        </Route>
        <Route render={(props) => <ForgotPass {...props} key={Date.now()} />} exact path="/forgot_password">
        </Route>
        <Route render={(props) => <UserProfile {...props} key={Date.now()} />} path="/profile">
        </Route>
        <Route render={(props) => <ChangePassword {...props} key={Date.now()} />} path="/change_password">
        </Route>
        <Route render={(props) => <Adminpage {...props} key={Date.now()} />} path="/admin_page">
        </Route>
        <Route render={(props) => <VerifyLink {...props} key={Date.now()} />} path="/verifytkn/:token">
        </Route>
        <Route render={(props) =>
          usertoken === null ? <Login /> : <Home {...props} key={Date.now()} />
        }
          path="/verifyMobile/:token">
        </Route>
        <Route render={(props) =>
          <Project {...props} key={Date.now()} />
        }
          path="/projId/:id">
        </Route>
        <Route render={(props) => <Deity {...props} key={Date.now()} />} path="/deity">
        </Route>
        <Route render={(props) => <Deity {...props} key={Date.now()} />} path="/deitymanagement">
        </Route>
        <Route render={(props) => <About {...props} key={Date.now()} />} path="/about">
        </Route>
        <Route render={(props) => <Ser {...props} key={Date.now()} />} path="/Ser">
        </Route>
        <Route render={(props) => <ContactUs {...props} key={Date.now()} />} path="/ContactUs">
        </Route>
        <Route render={(props) => <AboutUs {...props} key={Date.now()} />} path="/AboutUs">
        </Route>
        <Route render={(props) => <PrivacyPolicy {...props} key={Date.now()} />} path="/privacypolicy">
        </Route>
        <Route render={(props) => <News {...props} key={Date.now()} />} path="/PurchaseProtection">
        </Route>
        <Route render={(props) => <TourPackages {...props} key={Date.now()} />} path="/CancellationPolicy">
        </Route>
        <Route render={(props) => <Peetam {...props} key={Date.now()} />} path="/ReplacementPolicy">
        </Route>
        <Route render={(props) => <Terms {...props} key={Date.now()} />} path="/ConditionsofSale">
        </Route>
        <Route render={(props) => <Enquiry {...props} key={Date.now()} />} path="/Enquiry">
        </Route>
        <Route render={(props) => <Notifications {...props} key={Date.now()} />} path="/ReturnPolicy">
        </Route>
        <Route render={(props) => <JoinUs {...props} key={Date.now()} />} path="/JoinUs">
        </Route>
        <Route render={(props) => <Downloads {...props} key={Date.now()} />} path="/Downloads">
        </Route>
        <Route render={(props) => <RefundPolicy {...props} key={Date.now()} />} path="/RefundPolicy">
        </Route>
        <Route render={(props) => <TempAdmin {...props} key={Date.now()} />} path="/tempadmin">
        </Route>
        <Route render={(props) => <Temples {...props} key={Date.now()} />} path="/temples">
        </Route>
        <Route render={(props) => <Poojaofferings {...props} key={Date.now()} />} path="/offerings">
        </Route>
        <Route render={(props) => <Poojamaterials {...props} key={Date.now()} />} path="/materials">
        </Route>
        <Route render={(props) => <Myorder {...props} key={Date.now()} />} path="/orders">
        </Route>
        <Route render={(props) => <Order {...props} key={Date.now()} />} path="/order">
        </Route>
        <Route render={(props) => <OrderAdm {...props} key={Date.now()} />} path="/orderAdmin">
        </Route>
        <Route render={(props) => <Myorder {...props} key={Date.now()} />} path="/myorders">
        </Route>
        <Route render={(props) => <Mycart {...props} key={Date.now()} />} path="/mycart">
        </Route>
        <Route render={(props) => <Materialmanage {...props} key={Date.now()} />} path="/materialadm">
        </Route>

        <Route render={(props) => <Mymaterial {...props} key={Date.now()} />} path="/mymaterial">
        </Route>
        <Route render={(props) => <Grammercemanage {...props} key={Date.now()} />} path="/grammerceadm">
        </Route>
        <Route render={(props) => <Grammerce {...props} key={Date.now()} />} path="/grammerce">
        </Route>

        <Route render={(props) => <MaterialsVen {...props} key={Date.now()} />} path="/vendormaterials">
        </Route>
        <Route render={(props) => <Mygrammerce {...props} key={Date.now()} />} path="/mygrammerce">
        </Route>
        <Route render={(props) => <Materialordermanage {...props} key={Date.now()} />} path="/vendormateriaorders">
        </Route>
        <Route render={(props) => <Admmaterialorder {...props} key={Date.now()} />} path="/admmaterialorders">
        </Route>

        <Route render={(props) => <Admgrammereceorder {...props} key={Date.now()} />} path="/grammerceorderadm">
        </Route>
        <Route render={(props) => <Donations {...props} key={Date.now()} />} path="/donations">
        </Route>

        <Route render={(props) => <Donationmanage {...props} key={Date.now()} />} path="/donationmanage">
        </Route>
        <Route render={(props) => <Mydonation {...props} key={Date.now()} />} path="/mydonation">
        </Route>
        <Route render={(props) => <Project {...props} key={Date.now()} />} path="/project">
        </Route>
        <Route render={(props) => <ProjectDonationUser {...props} key={Date.now()} />} path="/projectdonationuser">
        </Route>
        <Route render={(props) => <ProjMan {...props} key={Date.now()} />} path="/projectmanage">
        </Route>
        <Route render={(props) => <Projectmanage {...props} key={Date.now()} />} path="/projectadmin">
        </Route>
        <Route render={(props) => <Myprojectdonation {...props} key={Date.now()} />} path="/myprojectdonation">
        </Route>
        <Route render={(props) => <Projectdonationadmin {...props} key={Date.now()} />} path="/donationadm">
        </Route>
        <Route render={(props) => <Projectdonation {...props} key={Date.now()} />} path="/projectdonate">
        </Route>
        {/* <Route render={(props) => <Admtemple {...props} key={Date.now()} />} path="/admtemple">
        </Route> */}
        <Route render={(props) => <AdmtempleList {...props} key={Date.now()} />} path="/admtemple">
        </Route>
        <Route render={(props) => <Addtemp {...props} key={Date.now()} />} path="/addTemple">
        </Route>
        {/* <Route render={(props) => <TempleAdmDet {...props} key={Date.now()} />} path="/editTemple">
        </Route> */}
        <Route render={(props) => <TempleAddEdit {...props} key={Date.now()} />} path="/addeditTemple">
        </Route>
        <Route render={(props) => <OfferManagement {...props} key={Date.now()} />} path="/offeringsmanagement">
        </Route>
        <Route render={(props) => <Ser {...props} key={Date.now()} />} path="/services">
        </Route>
        <Route render={(props) => <TermsConditions {...props} key={Date.now()} />} path="/termsandconditions">
        </Route>
        <Route render={(props) => <Report {...props} key={Date.now()} />} path="/reports"></Route>
        <Route render={(props) => <HomePageHeader {...props} key={Date.now()} />} path="/carousel" />
        <Route render={(props) => <Volunteer {...props} key={Date.now()} />} path="/volunteer" />
        <Route render={(props) => <PrasadhamDelivery {...props} key={Date.now()} />} path="/prasadhamDelivery" />
        <Route render={(props) => <Transportation {...props} key={Date.now()} />} path="/transportation" />
        <Route render={(props) => <FundReport {...props} key={Date.now()} />} path="/fundtobedisbursed" />
        <Route render={(props) => <RefundReport {...props} key={Date.now()} />} path="/fundrefunds" />
        <Route render={(props) => <Localmarket {...props} key={Date.now()} />} path="/localMarket" />
        <Route render={(props) => <DistrictComponenet {...props} key={Date.now()} />} path="/district" />
        <Route render={(props) => <DistrictComponenet {...props} key={Date.now()} />} path="/districtmanagement" />
        <Route render={(props) => <MarketManagement {...props} key={Date.now()} />} path="/marketManagement" />
        <Route render={(props) => <FundDisbursed {...props} key={Date.now()} />} path="/fundDisbursed" />
        <Route render={(props) => <VDC {...props} key={Date.now()} />} path="/vdc&other" />
        <Route render={(props) => <ImportFile {...props} key={Date.now()} />} path="/testfile" />
        <Route render={(props) => <VerifyEmail {...props} key={Date.now()} />} path="/verifyEmail" />
        <Route render={(props) =>
          <ReviewList {...props} key={Date.now()} />
        } path="/reviewList" />
        <Route render={(props) =>
          <FundForVolunteer {...props} key={Date.now()} />
        } path="/fundForVolunteer" />
        <Route render={(props) =>
          <UserBookingOrders {...props} key={Date.now()} />
        } path="/UserBookingOrders" />      </HashRouter>

    </IonApp>

  )

}


export default App;
