import {
  IonContent,
  IonText,
  IonItem,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItemDivider,
} from "@ionic/react";
import React, { useState } from "react";

import Foot from "./Foot";


import "./Home.css";
import "./PrivacyPolicy.css";

import HeadFoot from "./Head";

const TermsConditions: React.FC = () => {

  const userdata: any = useState(sessionStorage.getItem("UserData"));
  const [token, settoken]: any = useState(sessionStorage.getItem("token"));

  return (
    <IonPage>
      <IonContent>
        <iframe
          src={`${window.location.origin}/termsandconditions.pdf`}
          width="100%"
          height="100%"
          style={{ border: "none" }}
        />
      </IonContent>
    </IonPage>
  );
};

export default TermsConditions;